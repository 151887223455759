import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../utils/format";
import {
  getImageEventCatalog,
  getImageItem,
  getPlaceholderImage,
} from "../../../utils/image";
import { isMarketing } from "../../../utils/eventCatalog";

import "./WishListItemEventCatalog.scss";

const WishListItemEventCatalog = ({ items, t }) => {
  const placeholderImage = getPlaceholderImage();

  return (
    <div
      className={`wishListItemsEC ${
        items && items.length === 1 ? "wishListItem__oneItem" : ""
      }`}
    >
      {items &&
        items.map((item) => {
          return (
            <Link to={`/product/${item.id}`} key={item.id}>
              <div className={`wishListItemContainer `}>
                <div className={`wishListItem-img`}>
                  <img
                    src={
                      isMarketing(item)
                        ? getImageEventCatalog(item.imageEventCatalog)
                        : item.imageUrlWithBase
                    }
                    onError={(e) => {
                      e.target.src = placeholderImage;
                    }}
                    alt=""
                  />
                </div>
                <div className={`wishListItem-details `}>
                  <div className="wishListItem-name">{item.title}</div>
                  <div className="wishListItem-subtitle">{item.subtitle}</div>
                  <div className="wishListItem-size">
                    {t("wishlist.products.size")}: {item.size}
                  </div>
                  <div className="wishListItem-container">
                    <div className="wishListItem-quantity">
                      {t("wishlist.products.quantity")} : {item.quantity}
                    </div>

                    {item.price && (
                      <div className="wishListItem-price">
                        {formatCurrency(item.price, item.currency)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export default WishListItemEventCatalog;
