import axios from "axios";

var defaultHeaders = (session) => ({
  "Access-Control-Allow-Origin": "*",
  Authorization: "Bearer " + session,
  "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
  "Content-Security-Policy":
    "default-src 'self'; script-src 'self'; style-src 'self';",
  "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
  "X-Content-Type-Options": "nosniff",
});

export const get = async ({
  domain,
  service,
  headers,
  runOnce,
  session,
  config,
}) => {
  const endpoint = domain + service;

  try {
    const response = await axios.get(`${endpoint}`, {
      headers: { ...defaultHeaders(session), ...headers },
      ...(config || {}),
    });

    return response;
  } catch (err) {
    if (!runOnce) {
      console.log({ err });

      return await get({ domain, service, headers, runOnce: true, session });
    } else {
      console.log({ err });

      throw err.response.data;
    }
  }
};

export const post = async ({
  domain,
  service,
  body,
  headers,
  runOnce,
  session,
}) => {
  const endpoint = domain + service;

  try {
    const response = await axios.post(`${endpoint}`, body, {
      headers: { ...defaultHeaders(session), ...headers },
    });

    return response;
  } catch (err) {
    if (!runOnce) {
      return await post({
        domain,
        service,
        body,
        headers,
        runOnce: true,
        session,
      });
    } else {
      throw err.response.data;
    }
  }
};

export const patch = async ({
  domain,
  service,
  body,
  headers,
  runOnce,
  session,
}) => {
  const endpoint = domain + service;

  try {
    const response = await axios.patch(`${endpoint}`, body, {
      headers: { ...defaultHeaders(session), ...headers },
    });

    return response;
  } catch (err) {
    if (!runOnce) {
      return await patch({
        domain,
        service,
        body,
        headers,
        runOnce: true,
        session,
      });
    } else {
      throw err.response.data;
    }
  }
};
