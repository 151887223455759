
import * as axios from './axios';


export const getConfigDataHub = async(accessToken, instanceUrl) => {
    const { data } = await axios.get({
        domain: instanceUrl,
        service: `/services/apexrest/clt/v1/wishlist/eventCatalog`,
        session: accessToken
    });
    const response = data.record;
    return {
        access_token: response.AccessToken__c,
    }
}