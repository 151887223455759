import * as axios from "./axios";

import { getConfigDataHub } from "./datahub";

const getBase64FromImage = (image) => {
  const res = image && Buffer.from(image, "binary").toString("base64");
  return `data:;base64, ${res}`;
};

export const isMarketing = item => item.shootingAsset === ('' || 'marketing');

const callDataHubImage = async ({
  url,
  access_token,
}) => {
  try {
    const response = await axios.get({
      domain: url,
      service: "",
      headers: {
        Authorization: `Bearer ${access_token}`,
        responseType: "arraybuffer",
      },
      session: access_token,
      config: {
        responseType: "arraybuffer",
      },
    });
    return getBase64FromImage(response.data);
  } catch (error) {
    return "";
  }
};

export const getImageFromDataHub = async ({ wishlist, accessToken, instanceUrl}) => {
  try {
    const { items } = wishlist;
    const { access_token } =
      (await getConfigDataHub(accessToken, instanceUrl)) || {};

    const callApiDataHub = items.map((item) => {
      if (isMarketing(item)) {
        return callDataHubImage({
          url: item.imageUrlWithBase,
          access_token,
        })
      }
      else {
        return item;
      }
    }

    );
    const allImagesDataHub = await Promise.all(callApiDataHub);
    return items.map((item, index) => {
      if (isMarketing(item)) {
        return {
          ...item,
          imageEventCatalog: allImagesDataHub[index],
        };
      } else {
        return {
          ...item,
          imageEventCatalog: item.imageUrlWithBase,
        };
      }
    });
  } catch (error) {
    console.log(error);
    return;
  }
};
